import request from '@/utils/request-login'

// 登录相关接口列表
const apis = {
    checkLogin: '/admin/user/checkLogin', // 登录
    requestPhoneCode: '/admin/yijiajiaUserInfo/RequestPhoneCode', // 获取验证码
}

export function login(parameter) {
    return request({
        url: apis.checkLogin,
        method: 'post',
        data: parameter
    })
}

export function getSmsCaptcha(parameter) {
    return request({
        url: apis.requestPhoneCode,
        method: 'post',
        data: parameter
    })
}

