<template>
    <div class="login-wrap">
        <div class="left-wrap">
            <img class="login-logo" src="../../assets/imgs/login_logo.png" title="产业数据中台" alt="产业数据中台">
            <img class="login-text" src="../../assets/imgs/login_text.png" alt="聚势赋能，共享未来">
        </div>
        <div class="right-wrap">
            <div class="login-form">
                <div class="tabs">
                    <span class="tab-item" :class="{act: loginType == 1}" @click="swithTab(1)">短信登录</span>
                    <span class="tab-item" :class="{act: loginType == 2}" @click="swithTab(2)">账号密码登录</span>
                </div>
                <div class="code-form" v-if="loginType == 1">
                   <a-form-model 
                        ref="formCode"
                        :model="formCode" 
                        :rules="rulesCode"
                        @submit="handleCodeSubmit" 
                        @submit.native.prevent
                    >
                        <a-form-model-item prop="phone">
                            <a-input v-model="formCode.phone" placeholder="手机号" size="large">
                                <IcPhone slot="prefix" />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item prop="code">
                            <a-input class="code-input" v-model="formCode.code" type="text" placeholder="验证码"  size="large">
                                <IcLock slot="prefix" />
                            </a-input>
                            <a-button
                                class="code-btn"
                                size="large"
                                @click="getSmsCode"
                                :disabled="smsCaptcha.isWaiting"
                            >
                                {{smsCaptcha.showText}}
                            </a-button>
                        </a-form-model-item>
                        <a-form-model-item style="margin-bottom: 16px">
                            <a-button
                                class="login-submit"
                                type="primary"
                                html-type="submit"
                                size="large"
                                :disabled="formCode.phone === '' || formCode.code === ''"
                            >
                                登录
                            </a-button>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-checkbox v-model="formCode.rememberMe">
                                <span style="color: #666">记住我</span>
                            </a-checkbox>
                        </a-form-model-item>
                    </a-form-model>
                </div>
                <div class="pass-form" v-if="loginType == 2">
                    <a-form-model
                        ref="formPwd"
                        :model="formPwd"
                        @submit="handlePwdSubmit"
                        @submit.native.prevent
                        :rules="rulesPwd"
                    >
                        <a-form-model-item prop="account">
                            <a-input v-model="formPwd.account" placeholder="账号" size="large">
                                <IcUser slot="prefix" />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item prop="password">
                            <a-input v-model="formPwd.password" type="password" placeholder="密码"
                                size="large" 
                            >
                                <IcLock slot="prefix" />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item  style="margin-bottom: 16px">
                            <a-button
                                class="login-submit"
                                type="primary"
                                size="large"
                                html-type="submit"
                                :disabled="formPwd.account === '' || formPwd.password === ''"
                                :loading="loadingPwd"
                            >
                                登录
                            </a-button>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-checkbox v-model="formPwd.rememberMe">
                                <span style="color: #666">记住我</span>
                            </a-checkbox>
                        </a-form-model-item>
                    </a-form-model>
                </div>
            </div>
            <div class="copyright">Copyright &copy; 2017-2021 浙江省服装产业创新服务综合体</div>
        </div>
    </div>
</template>

<script>
import IcPhone from '../../assets/icons/ic_phone.svg'
import IcLock from '../../assets/icons/ic_lock.svg'
import IcUser from '../../assets/icons/ic_user.svg'
import {validateMobile} from '@/utils/validate'
import {login, getSmsCaptcha} from '@/api/login'
export default {
    components: {
        IcPhone,
        IcLock,
        IcUser
    },
    data() {
        let validatePhone = (rule, value, callback) => {
            // console.log(rule, value, callback)
            value = value.trim()
            if (value === '') {
                callback(new Error('请输入手机号'))
            } else if (!validateMobile(value)) {
                callback(new Error('手机号格式不正确'))
            } else {
                callback();
            }
        }
        return {
            loginType: 1, // 1 - 短信登录   2 - 密码登录
            loadingPwd: false, // loading标记
            loadingCode: false, // loading标记
            formPwd: {
                account: "",
                password: '',
                rememberMe: false
            },
            formCode: {
                phone: '',
                code: '',
                rememberMe: false
            },
            rulesPwd: {
                account: [{ validator: validatePhone, trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur'}]
            },
            rulesCode: {
                phone: [{ validator: validatePhone, trigger: 'blur' }],
                code: [{ required: true, message: '请输入验证码', trigger: 'blur'}]
            },
            // 获取验证码
            smsCaptcha: {
                isWaiting: false,
                timer: null,
                second: 60,
                showText: '获取验证码',
            }
                
            
        };
    },
    methods: {
        // 获取验证码
        getSmsCode() {
            if (!this.smsCaptcha.isWaiting) {
                // 校验手机号
                let phone = this.formCode.phone.trim()
                if (phone === '') return this.$message.error('请输入手机号')
                if (!validateMobile(phone)) return this.$message.error('手机号格式不正确')

                this.smsCaptcha.isWaiting = true

                // 重置定时器
                let resetTimer = () => {
                    clearInterval(this.smsCaptcha.timer)
                    this.smsCaptcha.second = 60
                    this.smsCaptcha.showText = '获取验证码'
                    this.smsCaptcha.isWaiting = false
                }

                // 请求接口
                getSmsCaptcha({phone}).then(res => {
                    // console.log(res)
                    if (res.errorCode === 200) {
                        this.$message.success('验证码发送成功!')
                    } else {
                        this.$message.error(res.errorMsg || '网络异常，请稍后重试')
                        resetTimer()
                    }
                }).catch(err => {
                    console.warn(err)
                    resetTimer()
                })

                // 倒计时
                clearInterval(this.smsCaptcha.timer)
                this.smsCaptcha.showText = this.smsCaptcha.second + 's'
                this.smsCaptcha.timer = setInterval(() => {
                    if (this.smsCaptcha.second > 0) {
                        this.smsCaptcha.second --
                        this.smsCaptcha.showText = this.smsCaptcha.second + 's'
                    } else {
                        resetTimer()
                    }
                }, 1000)
            }
        },
        // 登录返回数据处理
        loginResolvedCb(res, rememberMe) {
            console.log(res)
            if (res.errorCode === 200) {
                this.$message.success('登录成功')
                // console.log('...')
                // console.log(JSON.parse(res.data.menu))
                this.$store.dispatch('setLoginInfo', {
                    rememberMe,
                    loginInfo: {
                        adminCellphone: res.data.adminCellphone,
                        adminCompanyName: res.data.adminCompanyName,
                        adminId: res.data.adminId,
                        adminPosition: res.data.adminPosition,
                        adminRealname: res.data.adminRealname,
                        userLoginToken: res.data.userLoginToken,
                        yijiajiaUserToken: res.data.yijiajiaUserToken,
                    }
                })
                this.$router.replace('/')
            } else {
                this.$message.error(res.errorMsg || '网络异常，请稍后重试')
            }
        },
        // 验证码登录
        handleCodeSubmit() {
            this.$refs.formCode.validate(valid => {
                if (valid) {
                    console.log('submit!')
                    this.loadingCode = true
                    login({
                        phone: this.formCode.phone.trim(),
                        code: this.formCode.code.trim(),
                        userDeviceInfo: window.navigator.userAgent,
                        type: '1',
                    }).then(res => {
                        this.loginResolvedCb(res, this.formCode.rememberMe)
                    }).catch(err => {
                        console.error(err)
                    }).finally(() => {
                        this.loadingCode = false
                    })
                }
            });
        },
        // 密码登录
        handlePwdSubmit() {
            this.$refs.formPwd.validate(valid => {
                if (valid) {
                    console.log('submit!')
                    this.loadingPwd = true
                    login({
                        phone: this.formPwd.account.trim(),
                        adminPasswd: this.formPwd.password.trim(),
                        userDeviceInfo: window.navigator.userAgent,
                        type: '2',
                    }).then(res => {
                        this.loginResolvedCb(res, this.formPwd.rememberMe)
                    }).catch(err => {
                        console.error(err)
                    }).finally(() => {
                        this.loadingPwd = false
                    })
                }
            });
        },
        swithTab(type) {
            this.loginType = type
            if (type === 1) {
                this.$refs.formPwd.clearValidate()
            }
            if (type === 2) {
                this.$refs.formCode.clearValidate()
            }
        }
    },
};
</script>

<style lang="less">
@primary-color: #0267ED;
.login-wrap {
    min-width: 1000px;
    height: 100%;
    display: flex;
    .left-wrap {
        width: 45%;
        height: 100%;
        background: url(../../assets/imgs/login_bg.jpg) no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .login-logo {
            position: absolute;
            left: 40px;
            top: 40px;
            width: 184px;
            height: 59px;
        }
        .login-text {
            width: 342px;
            height: 150px;
        }
    }
    .right-wrap {
        width: 55%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .login-form {
            margin-top: -48px;
            width: 368px;
            .tabs {
                height: 44px;
                margin-bottom: 24px;
                display: flex;
                align-items: center;
            }
            .tab-item {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 24px;
                cursor: pointer;
                position: relative;
                margin-right: 56px;
                transition: all 0.3s;
                &:hover {
                    color: @primary-color;
                }
                &.act {
                    color: @primary-color;
                    &::after {
                        content: ' ';
                        width: 100%;
                        height: 2px;
                        background-color: @primary-color;
                        position: absolute;
                        left: 0;
                        bottom: -10px;

                    }
                }
            }
            .code-input {width: 68%;}
            .code-btn {
                width: 30%; margin-left: 2%; 
                // font-size: 14px;
                // line-height: inherit;
            }
            .login-submit {
                margin-top: 24px;
                width: 100%;
            }
        }
        .copyright {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 70px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 20px;
            text-align: center;
        }
    }
}
</style>