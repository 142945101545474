import axios from 'axios'
import API_PREFIX from '../config/api.prefix'
import {message} from 'ant-design-vue'
// 创建axios实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: API_PREFIX.login_base_url,
    // 请求超时时间
    timeout: 10000 
})

request.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    return config
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error)
});

request.interceptors.response.use(response => {
    // 对响应数据做点什么
    if (!response.data.errorCode) {
        message.error('网络异常，请稍后重试')
    }
    return response.data
}, error => {
    // 对响应错误做点什么
    message.error('网络异常，请稍后重试')
    return Promise.reject(error)
})

export default request

